exports.components = {
  "component---src-pages-100-top-components-js": () => import("./../../../src/pages/100-top-components.js" /* webpackChunkName: "component---src-pages-100-top-components-js" */),
  "component---src-pages-100-top-hospitals-100-top-hospitals-test-results-js": () => import("./../../../src/pages/100-top-hospitals/100-top-hospitals-test/results.js" /* webpackChunkName: "component---src-pages-100-top-hospitals-100-top-hospitals-test-results-js" */),
  "component---src-pages-100-top-hospitals-15-top-health-systems-results-js": () => import("./../../../src/pages/100-top-hospitals/15-top-health-systems/results.js" /* webpackChunkName: "component---src-pages-100-top-hospitals-15-top-health-systems-results-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browser-detect-js": () => import("./../../../src/pages/browser-detect.js" /* webpackChunkName: "component---src-pages-browser-detect-js" */),
  "component---src-pages-placeholder-js": () => import("./../../../src/pages/placeholder.js" /* webpackChunkName: "component---src-pages-placeholder-js" */),
  "component---src-pages-scratchpad-js": () => import("./../../../src/pages/scratchpad.js" /* webpackChunkName: "component---src-pages-scratchpad-js" */),
  "component---src-pages-states-js": () => import("./../../../src/pages/states.js" /* webpackChunkName: "component---src-pages-states-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-applied-sciences-js": () => import("./../../../src/templates/appliedSciences.js" /* webpackChunkName: "component---src-templates-applied-sciences-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-filter-js": () => import("./../../../src/templates/categoryFilter.js" /* webpackChunkName: "component---src-templates-category-filter-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-hundred-top-js": () => import("./../../../src/templates/hundredTop.js" /* webpackChunkName: "component---src-templates-hundred-top-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-premier-events-js": () => import("./../../../src/templates/premierEvents.js" /* webpackChunkName: "component---src-templates-premier-events-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-stanson-health-js": () => import("./../../../src/templates/stansonHealth.js" /* webpackChunkName: "component---src-templates-stanson-health-js" */),
  "component---src-templates-text-only-page-js": () => import("./../../../src/templates/textOnlyPage.js" /* webpackChunkName: "component---src-templates-text-only-page-js" */),
  "component---src-templates-topic-filter-js": () => import("./../../../src/templates/topicFilter.js" /* webpackChunkName: "component---src-templates-topic-filter-js" */)
}

